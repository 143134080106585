import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

const table = '#TestResults';

let moduleState = {
	maxRows: 15,
	currentRows: null,
	totalRows: 0,
};

var module = {

	init: function () {
		subscribe('/filter/applied', module._initPagination);

		module._setState();

		$('#maxRows').on('change', module._maxChanged).val(15);
		$(document).on('click', '.pagination li', module._changePage);
	},

	_setState: function () {
		let currentRows = $(table + ' tbody .js-filter-row[aria-current=true]');

		moduleState = {
			maxRows: parseInt($('#maxRows').val()),
			currentRows: currentRows,
			totalRows: currentRows.length
		}
	},

	_maxChanged: function (evt) {
		module._setState();

		//console.log(moduleState);

		if (moduleState.totalRows < moduleState.maxRows) {
			$('.pagination').hide();
		} else {
			$('.pagination').show();
		}

		var trnum = 0; // reset tr counter
		$(moduleState.currentRows).each(function () {
			trnum++; // Start Counter
			if (trnum > moduleState.maxRows) {
				$(this).hide(); // fade it out
			}

			if (trnum < moduleState.maxRows) {
				$(this).show();
			}
		});

		if (moduleState.totalRows > moduleState.maxRows) {
			module._buildPageNumberList();
		}

		module._limitPagging();
	},

	_changePage: function (evt) {

		evt.stopImmediatePropagation();
		evt.preventDefault();

		var activePage = $('.pagination-number.active').attr('data-page');
		var lastPage = $('.pagination-number:last()').attr('data-page');
		var pageNum = $(this).attr('data-page'); // get it's number

		if (pageNum == 'prev') {
			if (activePage == 1) {
				return;
			}
			pageNum = --activePage;
		}

		if (pageNum == 'next') {
			if (activePage == lastPage) {
				return;
			}
			pageNum = ++activePage;
		}

		activePage = pageNum;
		var trIndex = 0;

		$('.pagination li').removeClass('active'); // remove active class from all li
		$('.pagination [data-page="' + activePage + '"]').addClass('active');

		module._limitPagging();
		$(moduleState.currentRows).each(function () {

			trIndex++; // tr index counter

			if (
				trIndex > moduleState.maxRows * pageNum ||
				trIndex <= moduleState.maxRows * pageNum - moduleState.maxRows
			) {
				$(this).hide();
			} else {
				$(this).show();
			}
		});
	},

	_initPagination: function () {
		module._setState();
		$('#maxRows').change();
	},

	_buildPageNumberList: function () {
		var pagenum = Math.ceil(moduleState.totalRows / moduleState.maxRows);

		// remove pagination numbers
		$('.pagination')
			.find('li')
			.slice(1, -1)
			.remove();

		for (var i = 1; i <= pagenum;) {
			$('.pagination #prev')
				.before(
					'<li class="pagination-number" data-page="' + i + '"><span>' + i++ + '<span class="sr-only"></span></span></li>'
				)
				.show();
		}
		$('.pagination [data-page="1"]').addClass('active');
	},

	_limitPagging: function () {

		if ($('.pagination li').length > 7) {
			if ($('.pagination li.active').attr('data-page') <= 3) {
				$('.pagination li:gt(5)').hide();
				$('.pagination li:lt(5)').show();
				$('.pagination [data-page="next"]').show();
			} if ($('.pagination li.active').attr('data-page') > 3) {
				$('.pagination li:gt(0)').hide();
				$('.pagination [data-page="next"]').show();
				for (let i = (parseInt($('.pagination li.active').attr('data-page')) - 2); i <= (parseInt($('.pagination li.active').attr('data-page')) + 2); i++) {
					$('.pagination [data-page="' + i + '"]').show();
				}
			}
		}
	}
}

export { module as Pagination };