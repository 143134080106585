import $ from 'jquery';
import { Pagination } from 'pagination';
import { publish, subscribe } from 'Util/pubsub';

const selectors = {
	table: '.js-table-totals',
	row: '.js-table-totals__row[aria-current=true]',
	output: '.js-table-totals__output',

	sum: '.js-table-totals__sum',
	avg: '.js-table-totals__avg',

	netkg: '.js-table-totals__netkg'
};

const dataAttributes = {
	type: 'data-total-type'
};

const constants = {
	INT: 'int',
	DEC: 'dec',
	C: 'currency'
}

const model = {
	$table: null,
	$output: null
};

var module = {

	init: function () {

		model.$table = $(selectors.table);
		model.$output = $(selectors.output);

		subscribe('/filter/applied', module._calculate);
	},

	_calculate: function () {
		let data = module._getData();

		module._calculateSums(data);
		module._calculateAvgs(data);
	},

	_getData: function () {

		let data = [];
		let $rows = model.$table.find(selectors.row);

		for (let i = 0, l = $rows.length; i < l; i++) {

			let rowData = {
				weight: 0,
				sums: [],
				avgs: []
			};

			let $row = $rows.eq(i);
			let netkg = $row.find(selectors.netkg).text();
			let $sums = $row.find(selectors.sum);
			let $avgs = $row.find(selectors.avg);

			for (let j = 0, k = $sums.length; j < k; j++) {
				let $cell = $sums.eq(j);
				let raw = $cell.text();

				if (raw === '') {
					raw = 0;
				}

				let value = 0;

				// if cell is currency, get raw value from data attribute
				if ($cell.is(`[${dataAttributes.type}="${constants.C}"]`)) {
					value = parseFloat($cell.attr('data-raw-value'));
				} else {
					// if cell is not currency, parse raw value as int or float
					value = $cell.is(`[${dataAttributes.type}="${constants.DEC}"]`) ? parseFloat(raw) : parseInt(raw);
				}
				rowData.sums[j] = value;
			}

			for (let l = 0, m = $avgs.length; l < m; l++) {
				let $cell = $avgs.eq(l);
				let raw = $cell.text();
				if (raw === '') {
					raw = 0;
				}
				let value = $cell.is(`[${dataAttributes.type}="${constants.DEC}"]`) ? parseFloat(raw) : parseInt(raw);

				rowData.avgs[l] = value;
			}

			rowData.weight = parseInt(netkg);

			data.push(rowData);
		}

		return data;
	},

	_calculateSums: function (data) {

		let values = [];

		for (let i = 0, l = data.length; i < l; i++) {

			let row = data[i];

			for (let j = 0, k = row.sums.length; j < k; j++) {

				let value = row.sums[j];

				if (values[j]) {
					values[j] += value;
				} else {
					values[j] = value;
				}
			}
		}

		module._displaySums(values);
	},

	_displaySums: function (values) {

		let $cells = model.$output.find(selectors.sum);

		for (let j = 0, k = $cells.length; j < k; j++) {
			let $cell = $cells.eq(j);
			let value = Math.round(values[j])

			// if he cell is currency, format the value as currency and remove the currency code (NZ)
			if ($cell.is('.is-currency')) {
				value = value.toLocaleString('en-GB', { style: 'currency', currency: 'NZD' }).slice(2);
			}

			if (values.length > 0) {
				$cell.text(value);
			}
		}
	},

	_calculateAvgs: function (data) {

		let values = [];
		let totalWeight = data.reduce((accumulator, item) => accumulator + item.weight, 0);

		if (data[0] !== undefined) {
			let numOfAvgs = data[0].avgs.length;

			if (totalWeight === 0) {
				totalWeight = 1; // no div by zero errahs
			}

			for (let i = 0, l = numOfAvgs; i < l; i++) {

				let weightedNumberTotal = data.reduce((accumulator, item) => accumulator + (item.avgs[i] * item.weight), 0);
				let weightedAvg = weightedNumberTotal / totalWeight;

				values[i] = weightedAvg;
			}
		}


		module._displayAvgs(values);
	},

	_displayAvgs: function (values) {

		let $cells = model.$output.find(selectors.avg);

		for (let j = 0, k = $cells.length; j < k; j++) {
			let $cell = $cells.eq(j);

			if (values.length > 0) {

				if ($($cell).hasClass('dec')) {
					$cell.text(values[j].toFixed(1));
				} else {
					$cell.text(Math.round(values[j]));
				}

				
			}
		}
	}
};

export { module as TableTotals };