/* App/notifications/notifications */

import $ from 'jquery';
import Velocity from 'velocity-animate';

var selectors = {
    notifications: '.js-notifications',
    toggle: '.js-notifications-toggle',
    close: '.js-notifications-close',
	item: '.js-notifications-item',
	link: '.js-notifications-link',
	dismiss: '.js-notifications-dismiss'
};

var stateAttributes = {
	hidden: 'aria-hidden'
};

var dataSelectors = {
	dismissApi: 'data-notification-dismiss'
};

var $notifications;
var $toggle;

var Notifications = {

    init: function () {
	    $notifications = $(selectors.notifications);
        $toggle = $(selectors.toggle);

		Notifications._initEvents();
		Notifications._outsideClick();
    },

	_initEvents: function () {
        $toggle.on('click', Notifications._toggleNotifications);
		$notifications.on('click', selectors.close, Notifications._closeNotifications);
		//MGS-68
		/*$notifications.on('click', selectors.link, Notifications._notificationClick);*/
		$notifications.on('click', selectors.link, Notifications._closeNotifications);
        $notifications.on('click', selectors.dismiss, Notifications._notificationDismiss);
    },

    _toggleNotifications: function (event) {
        event.preventDefault();

        var hidden = $notifications.attr(stateAttributes.hidden);

        if (hidden === 'true') {
            $notifications.attr(stateAttributes.hidden, 'false');
			Velocity($notifications, 'slideDown', { duration: 250 });
        } else {
	        Notifications._closeNotifications();
        }
	},

	_outsideClick: function () {

		document.addEventListener('click', function (event) {
			var hidden = $notifications.attr(stateAttributes.hidden);
			const box = document.getElementById('c-portal-header__notifications');

			if (hidden === 'false' && !box.contains(event.target)) {
				Notifications._closeNotifications();
			}
		});

	},

    _closeNotifications: function (event) {
	    $notifications.attr(stateAttributes.hidden, 'true');
		Velocity($notifications, 'slideUp', { duration: 250 });
    },

    _notificationClick: function(event) {
		event.preventDefault();
		var $item = $(this).closest(selectors.item);
		var endpoint = Notifications._getEndpoint($item);

		if (endpoint !== '') {
			fetch(endpoint)
				.then(response => {
					if (response.ok) {
						Notifications._removeNotification($item);
						window.location = event.target.href;
					} else {
						throw new Error('Notification could not be dismissed');
					}
				})
				.catch(error => console.error(error));
		}
	},

	_notificationDismiss: function (event) {
		event.preventDefault();
		var $item = $(this).closest(selectors.item);
		var endpoint = Notifications._getEndpoint($item);

		if (endpoint !== '') {
			fetch(endpoint)
				.then(response => {
					if (response.ok) {
						Notifications._removeNotification($item);
					} else {
						throw new Error('Notification could not be dismissed');
					}
				})
				.catch(error => console.error(error));
		}
	},

    _getEndpoint: function($item) {
        var endpoint = $item.attr(dataSelectors.dismissApi);

        if (endpoint) {
	        return endpoint;
        } else {
	        return '';
        }
    },

    _removeNotification: function($item) {
        Velocity($item, 'slideUp', { duration: 250 }).then(function() {
	        $item.empty().remove();
        });
    }
};

export { Notifications };
