/* app/ui/wool-details/wool-details */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';

const selectors = Object.freeze({
	btn: '.js-wool-details-btn',
	backbtn: '.js-wool-details-backbtn'
});

let $btns;
let $backbtn;

const WoolDetails = {

	init: function() {

		WoolDetails.initElements();
		WoolDetails.initMediaQueries();
	},

	initElements: function() {
		$btns = $(selectors.btn);
		$backbtn = $(selectors.backbtn);
	},

	initMediaQueries: function() {

		MediaQueries.register([
			{
				queries: MediaQueries.queries['under-medium'],
				match: function () {
					WoolDetails.clearActiveSection();
					WoolDetails.bindEvents();
				},
				unmatch: function () {
					WoolDetails.unbindEvents();
					WoolDetails.clearActiveSection();
				}
			},
			{
				queries: MediaQueries.queries['over-medium'],
				match: function() {
					WoolDetails.unbindEvents();
					WoolDetails.clearActiveSection();
				},
				unmatch: function () {
					WoolDetails.clearActiveSection();
					WoolDetails.bindEvents();
				}
			}
		]);
	},

	bindEvents: function () {

		if ($btns.length) {
			$btns.on('click', WoolDetails.onButtonClick);
		}

		if ($backbtn.length) {
			$backbtn.on('click', WoolDetails.clearActiveSection);
		}
	},

	unbindEvents: function() {

		if ($btns.length) {
			$btns.off('click', WoolDetails.onButtonClick);
		}
	},

	onButtonClick: function(event) {
		event.preventDefault();
		WoolDetails.clearActiveSection();

		let $btn = $(this);
		let sectionName = $btn.data('section');
		let $section = $('#' + sectionName);

		if ($section.length) {
			$section.addClass('is-active');
		}
	},

	clearActiveSection: function() {
		$('.c-wool-details__section.is-active').removeClass('is-active');
	}
};

export { WoolDetails };
