/* App/event-listing/event-listing */

import $ from 'jquery';

var selectors = {
    calendar: '.js-calendar',
    month: '.js-calendar-month',
    year: '.js-calendar-year',
    nav: '.js-calendar-nav',
    prev: '.js-calendar-prev',
    next: '.js-calendar-next',
    grid: '.js-calendar-grid',
    gridDate: '.js-calendar-grid__date',
    detailLink: '.js-calendar-item__detail-link',
    panelContainer: '.js-calendar-panel__container',
    detailsPanel: '.js-calendar-panel__details',
    detailContainer: '.js-calendar-panel__details-container',
    detailContainerClose: '.js-calendar-panel__close',
    monthNext: '.js-calendar-header__next'
};

var dataSelectors = {
	gridDateApi: 'data-eventsforday-url',
	currentYear: 'current-year',
    currentMonth: 'current-month'
};

var $calendar;
var $calendarMonth;
var $calendarYear;
var initYear;
var initMonth;
var currentYear;
var currentMonth;
var $navPrev;
var $navNext;
var $grid;

var EventListing = {

	init: function() {
		$calendar = $(selectors.calendar);

        if ($calendar.length) {
	        $calendarYear = $calendar.find(selectors.year);
            $calendarMonth = $calendar.find(selectors.month);
            $navPrev = $calendar.find(selectors.prev);
	        $navNext = $calendar.find(selectors.next);
	        $grid = $calendar.find(selectors.grid);
	        initYear = parseInt($calendar.data('cal-year'), 10);
	        initMonth = parseInt($calendar.data('cal-month'), 10);
	        currentYear = initYear;
            currentMonth = initMonth;

			EventListing._initEvents();
			EventListing._initCheck();
		}
	},

	_initEvents: function () {
		$calendar.on('click', selectors.nav, EventListing._updateGrid);
		$calendar.on('click', selectors.gridDate, EventListing._gridDateClick);
		$calendar.on('click', selectors.detailLink, EventListing._detailLinkClick);
		$calendar.on('click', selectors.detailContainerClose, EventListing._detailClose);
		$calendar.on('click', selectors.monthNext, EventListing._monthNextClick);
	},

	_updateGrid: function(event) {
		event.preventDefault();
		var $btn = $(this);
		var dir = $btn.data('dir');
		var newYear = currentYear;
		var newMonth = currentMonth + 1;

		if (dir === 'next') {
			if (currentMonth === 12) {
				newYear = currentYear + 1;
				newMonth = 1;
			}
		} else {
			if (currentMonth === 1) {
				newYear = currentYear - 1;
				newMonth = 12;
			} else {
				newMonth = currentMonth - 1;
			}
		}

        var navUrl = '/cal/' + newYear + '/' + newMonth;
        currentYear = newYear;
        currentMonth = newMonth;

        fetch(navUrl).then(EventListing._calendarResponse);
    },

    _calendarResponse: async function (response) {
	    // set new month / year
        // check response
        // update month and year values
        // update buttons - disable or enable based on new year
        // inject new month grid
        var html = await response.text();
        var date = new Date();
        date.setYear(currentYear);
        date.setMonth(currentMonth - 1);
        var year = date.getFullYear();
        var month = date.toLocaleString([], { month: 'long' });

        if (response.ok) {
	        if (currentMonth === initMonth && currentYear === initYear) {
		        $navPrev.attr('disabled', true);
	        } else {
		        $navPrev.attr('disabled', false);
	        }
	        $calendarMonth.text(month);
	        $calendarYear.text(year);
	        $grid.html(html);
        }
    },

    // grid item
    _gridDateClick: function (event) {
        event.preventDefault();
        var $target = $(event.currentTarget);
        var api = $target.attr(dataSelectors.gridDateApi);

        fetch(api).then(EventListing._gridDateResponse);
    },

    _gridDateResponse: async function (response) {
        var html = await response.text();
        var $html = $(html);
        var count = $html.find(selectors.detailLink).length;

        if (count === 1) {
            // Open Detail
            var $target = $html.find(selectors.detailLink).first();
            var fakeEvent = {
                preventDefault: function () { },
                currentTarget: $target[0]
            };

            EventListing._detailLinkClick(fakeEvent);
        }
        else {
            // Populate panel
            $html.addClass(selectors.panelContainer.substring(1));
            $(selectors.panelContainer).replaceWith($html);
            $(selectors.detailsPanel).removeClass("Active");
        }
    },

    // details
    _detailLinkClick: function (event) {
        event.preventDefault();
        var $target = $(event.currentTarget);
        var api = $target.attr('href');

        fetch(api).then(EventListing._detailLinkResponse);
    },

    _detailLinkResponse: async function (response) {
        var html = await response.text();
        var $html = $(html);

        $html.addClass(selectors.detailContainer.substring(1));
        $(selectors.detailContainer).replaceWith($html);
        $(selectors.detailsPanel).addClass("Active");
    },

    _detailClose() {
        $(selectors.detailsPanel).removeClass("Active");
    },

    // next month
    _monthNextClick: function (event) {
        event.preventDefault();
        var $target = $(event.currentTarget);
        var api = $target.attr('href');

        fetch(api).then(EventListing._monthNextResponse);
    },

    _monthNextResponse: async function (response) {
        var html = await response.text();
        var $html = $(html);

        $html.addClass(selectors.grid.substring(1));
        $(selectors.grid).html($html);
    },

    // Init
    _initCheck() {
        document.addEventListener("DOMContentLoaded", () => {

            var url = window.location.toString();

            if ( url.indexOf("calendar-events") !== -1 && url.indexOf("#") > 0) {
                var route = "/cal/detail/";
                var item = url.split("#").pop();
                var api = route + item;

                fetch(api).then(EventListing._detailLinkResponse);

            }
        });
    }
};

export { EventListing };
