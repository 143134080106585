import $ from 'jquery';
import { Pagination } from 'pagination';
import { publish, subscribe } from 'Util/pubsub';
import { TableTotals } from 'App/wool-table/wool-totals';

var selectors = {
	year: '.js-year-select',
	sold: '.js-sold-select',
	breed: '.js-breed-select',
	flock: '.js-flock-select',
	sale: '.js-sale-select',
	filterButton: '.js-wool-details__filter-button',

	filterItem: '.js-filter-row',
	currentItem: '.js-filter-row[aria-current=true]',
	tableWrapper: '.js-test-results-wrapper',
	noResults: '.js-filter-noresults'
}

var filterState = {
	season: null,
	type: null,
	sold: null,
	flock: null,
	sale: null
};

let $tableWrapper;
let $noResults;

var module = {

	init: function () {
		$(selectors.filterButton).on('click', module._applyFilter);

		$tableWrapper = $(selectors.tableWrapper);
		$noResults = $(selectors.noResults);

		Pagination.init();
		TableTotals.init();
		module._initEvents();

		module._applyFilter();
		module._filterSaleList();

		subscribe('/sortable/complete', module._applyFilter);
	},

	_initEvents: function () {
		$(selectors.year).on('change', module._filterSaleList);
	},

	_filterSaleList: function (event) {
		event?.preventDefault();

		filterState.season = $(selectors.year).children('option:selected').val();

		let saleOptions = $(selectors.sale).children('option');
		saleOptions.hide();
		saleOptions.filter(function () {
			return $(this).attr('data-season').indexOf(filterState.season) > -1;
		}).show();
	},

	_applyFilter: function (event) {
		event?.preventDefault();

		// update filterState
		filterState.season = $(selectors.year).children('option:selected').val();
		filterState.sold = $(selectors.sold).children('option:selected').val();
		filterState.breed = $(selectors.breed).children('option:selected').val();
		filterState.flock = $(selectors.flock).children('option:selected').val();
		filterState.sale = $(selectors.sale).children('option:selected').val();

		// set item current state
		$(selectors.filterItem).filter(module._updateCurrentState);

		$(selectors.filterItem).hide();
		var $visibleRows = $(selectors.currentItem);

		//console.log($visibleRows.length);

		if ($visibleRows.length === 0) {
			$tableWrapper.hide();
			$noResults.show();
		} else {
			$visibleRows.show();
			$tableWrapper.show();
			$noResults.hide();
		}

		publish('/filter/applied', []);
	},

	_updateCurrentState: function (idx, element) {
		var $item = $(element);

		var isYearMatch = filterState.season === $item.attr('data-season');
		var isSoldMatch = filterState.sold === 'All' || filterState.sold === $item.attr('data-sold') || $item.attr('data-sold') === '*';
		var isBreedMatch = filterState.breed === 'All' || filterState.breed === $item.attr('data-breed') || $item.attr('data-breed') === '*';
		var isFlockMatch = filterState.flock === 'All' || filterState.flock === $item.attr('data-flock') || $item.attr('data-flock') === '*';
		var isSaleMatch = filterState.sale === 'All' || filterState.sale === $item.attr('data-sale') || $item.attr('data-sale') === '*';

		if (isYearMatch && isSoldMatch && isBreedMatch && isFlockMatch && isSaleMatch) {
			$item.attr('aria-current', true);
		} else {
			$item.attr('aria-current', false);
		}
	},
};

export { module as WoolTable };