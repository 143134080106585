////////////////
// Every page //
////////////////

import $ from 'jquery';

// Mobile navigation toggle
var $nav = $('.js-nav');
var $navToggle = $('.js-nav-toggle');
var $navToggleIcon = $navToggle.find('.js-nav-toggle-icon');
var $navToggleText = $navToggle.find('.js-nav-toggle-text');

$navToggle.on('click', function () {
    if ($nav.is('[aria-expanded="true"]')) {
        $nav.attr('aria-expanded', false);
        $navToggleText.text('More');
        $navToggleIcon.toggleClass('iconf-more iconf-close');
    } else {
        $nav.attr('aria-expanded', true);
        $navToggleText.text('Close');
        $navToggleIcon.toggleClass('iconf-more iconf-close');
    }
});

$('.js-expand-toggle__trigger').on('click', function () {
    var $target = $(this).closest('.js-expand-toggle__target');
    if ($target.is('[aria-expanded="true"]')) {
        $target.attr('aria-expanded', false);
    } else {
        $target.attr('aria-expanded', true);
    }
});

// These modules are always used
//import { Cookie } from 'App/cookie/cookie';
//import 'tablescroll';

//Cookie.init();

// Table Scroll
// Check DOM for elements requiring JS
//const $tables = $('.c-rte').find('table');
//if ($tables.length) {
//    $tables.tableScroll();
//}

// Publish resize and scroll events for other modules to use
import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';
import { Status } from 'Util/status';

Status.init();

const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));

// These modules are used often enough, or are small enough, that it's worth always loading them
import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { TabbedAccordion } from 'App/tabbed-accordion/tabbed-accordion';
/*import { Filter } from 'App/filter/filter';*/
import { WoolTable } from 'App/wool-table/wool-table';
import { WoolDetails } from 'App/wool-details/wool-details';
import { Notifications } from 'App/notifications/notifications';
import { EventListing } from 'App/event-listing/event-listing';

import 'sortable';
import 'csv';

// Expand Collapse
if (document.querySelector('.js-expand-collapse')) {
	expandCollapse.init();
}

//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

// Notifications
Notifications.init();

// Event Listing
EventListing.init();

//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

// Tabbed Accordion
if (document.querySelector('.c-accordion__tab')) {
    TabbedAccordion.init();
}

// Filter
//if (document.querySelector('.c-table-header__action')) {
//    Filter.init();
//}

if (document.querySelector('.c-Wool-details-table')) {
    WoolTable.init();
}

// Wool Details
if (document.querySelector('.js-wool-details-btn')) {
    WoolDetails.init();
}

// D3 graph chunk add and init
if (document.querySelector('.c-graph')) {
    import(/* webpackChunkName: "Graph" */ 'App/graph/graph').then(({ RenderGraph }) => {
        RenderGraph.init();
    });
}

// Form validation chunk add and init
if (document.querySelector('.js-validate-form')) {
	import(/* webpackChunkName: "FormValidate" */ 'App/form/form.validate').then(({ FormValidate }) => {
		FormValidate.init();
	});
}

// Rebaseline Form
if (document.querySelector('.js-rebaseline')) {
	import(/* webpackChunkName: "FormRebaseline" */ 'App/form/form.rebaseline').then(({ FormRebaseline }) => {
		FormRebaseline.init();
	});
}

// Rebaseline Form
if (document.querySelector('.js-resources__search')) {
    import(/* webpackChunkName: "FormResourcesSearch" */ 'App/form/form.resources-search').then(({ FormResourcesSearch }) => {
        FormResourcesSearch.init();
    });
}

