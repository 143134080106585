var TabbedAccordion = {

    init: function () {
		const container = document.querySelectorAll(".c-accordion__item__container");
		const tabs = document.querySelectorAll(".c-accordion__tab");

		function toggleShow() {
			const target = this;
			const item = target.classList.contains("c-accordion__tab")
				? target
				: target.parentElement;
			const group = item.dataset.actabGroup;
			const id = item.dataset.actabId;

			tabs.forEach(function (tab) {
				if (tab.dataset.actabGroup === group) {
					if (tab.dataset.actabId === id) {
						tab.classList.add("c-accordion__active");
					} else {
						tab.classList.remove("c-accordion__active");
					}
				}
			});

			container.forEach(function (container) {
				const tabItem = container.parentElement;

				if (tabItem.dataset.actabGroup === group) {
					if (tabItem.dataset.actabId === id) {
						tabItem.classList.add("c-accordion__active");
					} else {
						tabItem.classList.remove("c-accordion__active");
					}
				}
			});
		}

		tabs.forEach(function (tab) {
			tab.addEventListener("click", toggleShow);
		});

    }
};

export { TabbedAccordion };