/* util/status */
/* Status v1.1 */

import $ from 'jquery';
import { publish, subscribe } from 'Util/pubsub';

const selectors = {
	status: '.js-status'
};

const Types = {
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR'
};

const events = {
	success: '/status/success',
	error: '/status/error'
};

// Delay is used for screen reader accessibility.
// Make sure it matches the delay on CSS animations or transitions
const delay = 100; // ms

let classes = {};
classes[Types.SUCCESS] = 'success';
classes[Types.ERROR] = 'error';

var Status = {

	init: function () {
		Status._initSubscriptions();
	},

	_initSubscriptions: function () {
		subscribe(events.success, Status.success);
		subscribe(events.error, Status.error);
	},

	_getEl: function ($status) {
		if (typeof $status === 'string') {
			$status = $($status);
		} else if (typeof $status === 'undefined') {
			$status = $(selectors.status);
		}

		if ($status.length === 0) {
			console.error('No status element could be found');
		}

		return $status;
	},

	_clearType: function ($status) {
		var type;
		var cssClass;

		for (type in Types) {
			if (Object.prototype.hasOwnProperty.call(Types, type)) {
				cssClass = classes[type];

				if ($status.hasClass(cssClass)) {
					$status.removeClass(cssClass);
				}
			}
		}
	},

	_setType: function (type, $status) {
		var cssClass = classes[type];

		if (!cssClass) {
			console.error('No CSS class is defined for status type ' + type);
			return;
		}

		Status._clearType($status);

		// Asynchronous so the class gets added back even if it was
		// just removed, ensuring any CSS animation is triggered
		window.setTimeout(function () {
			$status.addClass(cssClass);
		}, 10);
	},

	_clearMessage: function ($status) {
		$status.text('');
	},

	_setMessage: function (message, $status) {
		Status._clearMessage($status);
		window.setTimeout(function () {
			// Asynchronous so it can fire after
			// the element becomes visible, and
			// therefore be detected as a change
			// by screen readers.
			$status.text(message);
		}, delay);
	},

	_show: function (message, type, $status) {
		Status._setType(type, $status);
		Status._setMessage(message, $status);
	},

	clear: function ($status) {
		$status = Status._getEl($status);

		Status._clearType($status);
		Status._clearMessage();
	},

	success: function (message, $status) {
		$status = Status._getEl($status);

		Status._show(message, Types.SUCCESS, $status);
	},

	error: function (message, $status) {
		$status = Status._getEl($status);

		Status._show(message, Types.ERROR, $status);
	}
};

export { Status };